import React from "react"
import Info2column from "../../../components/Info2column"
import QuestionAndAnswer2colmun from "../../../components/QuestionAndAnswer2colmun"
import CommentText from "../../../components/CommentText"
import ColumnGrid from "../../../components/ColumnGrid"
import CommonButton from "../../../components/CommonButton"
import ModalLink from "../../../components/ModalLink"
import LayoutInterview from "../../../components/LayoutInterview"
import { AnchorLink } from "gatsby-plugin-anchor-links"
import Seo from "../../../components/Seo"

import IconInfo from "../../../images/icon_info.svg"
import IconPhoto from "../../../images/icon_photo.svg"
import QuestionAndAnswerToggle from "../../../components/QuestionAndAnswerToggle"
import FlagId from "../../../images/fkag_id.svg"

import FaceImg01 from "../../../images/interview/03/face_01.jpg"
import FaceImg02 from "../../../images/interview/03/face_02.jpg"
import css from "../stylesheets/interview.module.scss"
import { graphql } from "gatsby"
import Img from "gatsby-image"

export default ({ data }) => {
  return (
    <div className={css.InterviewDetail}>
      <Seo
        title={`Children Growing at the Workplace | INTERVIEW`}
        lang={`en`}
      />
      <LayoutInterview lang={`en`}>
        <Info2column id={`interview-03`} noBorder={true}>
          <div>
            <Img fluid={data.image1.childImageSharp.fluid} />
          </div>
          <div>
            <p className={css.date}>{`2020.3.25`}</p>
            <h3 className={`mb20`}>Children Growing at the Workplace</h3>
            <p>
              Ria Prestia Anggraini
              <small>（Age: 33）</small>× Ucu Suhendri
              <small>（Age: 33）</small>
            </p>
            <p>
              Ucu and Ria, from Indonesia, came to Japan as{" "}
              <AnchorLink
                className={`linkUnderline`}
                to="/en/basic-information/kaigo/#section-2"
              >
                EPA Care Worker Candidates
              </AnchorLink>
              . They worked at different places for the first few years, but
              they have been working together at{" "}
              <ModalLink id={`facility`} className={`linkUnderline`}>
                <span style={{ color: "black" }}>
                  ‘Mori no Ie Narita,’
                  <img src={IconPhoto} alt="" />
                </span>
                <div>
                  <h3>Workplace</h3>
                  <Img fluid={data.facility1.childImageSharp.fluid} />
                  <Img fluid={data.facility2.childImageSharp.fluid} />
                  <Img fluid={data.facility3.childImageSharp.fluid} />
                  <Img fluid={data.facility4.childImageSharp.fluid} />
                  <Img fluid={data.facility5.childImageSharp.fluid} />
                </div>
              </ModalLink>{" "}
              an intensive care home for the elderly in Narita, Chiba since 2016
              after their marriage. We interviewed how they manage both working
              and raising children while living in Japan.
            </p>
            <h3>PROFILE</h3>
            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>Nane</th>
                  <td>Ucu Suhendri</td>
                </tr>
                <tr>
                  <th>Workplace</th>
                  <td>
                    Care Worker at an intensive care home for the elderly run by
                    FUKUSHI-GAKUDAN Social Welfare Corporation ‘Mori no Ie
                    Narita’
                  </td>
                </tr>
                <tr>
                  <th>Birthplace</th>
                  <td>
                    <img src={FlagId} alt="Indonesia" className={`flag`} />
                    Sukabumi, West Java, Indonesia
                  </td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>33 (Born in 1986) </td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>
                    Bachelor of Nursing at Syarief Hidayatullah State Islamic
                    University
                  </td>
                </tr>
                <tr>
                  <th>Work Experience</th>
                  <td>Working as a nurse at a hospital for 1 year</td>
                </tr>
                <tr>
                  <th>Residence Status at Entry to Japan</th>
                  <td>EPA Care Worker Candidate</td>
                </tr>
                <tr>
                  <th>Arrival Date in Japan</th>
                  <td>May 2012</td>
                </tr>
              </tbody>
            </table>

            <table className={`profileTable`}>
              <tbody>
                <tr>
                  <th>Name</th>
                  <td>Ria Prestia Anggraini</td>
                </tr>
                <tr>
                  <th>Workplace</th>
                  <td>
                    Care Worker at an intensive care home for the elderly run by
                    FUKUSHI-GAKUDAN Social Welfare Corporation ‘Mori no Ie
                    Narita’
                  </td>
                </tr>
                <tr>
                  <th>Birthplace</th>
                  <td>
                    <img src={FlagId} alt="Indonesia" className={`flag`} />
                    Jakarta, Indonesia
                  </td>
                </tr>
                <tr>
                  <th>Age</th>
                  <td>33 (Born in 1987)</td>
                </tr>
                <tr>
                  <th>Education</th>
                  <td>Bachelor of Nursing at Stikes Binawan Jakarta</td>
                </tr>
                <tr>
                  <th>Work Experience</th>
                  <td>Working as a nurse at a hospital for 3 months</td>
                </tr>
                <tr>
                  <th>Residence Status at Entry to Japan</th>
                  <td>EPA Care Worker Candidate</td>
                </tr>
                <tr>
                  <th>Arrival Date in Japan</th>
                  <td>December 2013</td>
                </tr>
              </tbody>
            </table>
          </div>
        </Info2column>
        <QuestionAndAnswerToggle
          question={`How do you feel about nursing care?`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image2.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image3.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
                <p>
                  It took about one year to get used to my job. I have
                  experienced many difficulties, for example, I tried to help a
                  resident with their meal one time, but they refused to open
                  their mouth.
                </p>
                <p>
                  However, now I believe that nursing care is equal to caring
                  for individuals’ lives. From everyday life to
                  ‘end-of-life-care,’ it is important to respect and provide
                  adequate care for each individual.
                </p>
                <p>
                  The residents are not robots so they definitely have reasons
                  for their refusal. I don’t force myself these days but take
                  time instead. When they are falling asleep, I wipe their faces
                  with hot towels to help them to wake up.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image4.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`Ucu`}>
                <p>That’s right. It is important not to push the residents.</p>
                <p>
                  Nursing care includes supporting an individual’s fundamental
                  life activities such as eating, excreting and bathing. So you
                  are required to know each resident well. We report each
                  resident’s condition at a morning meeting and check it on the
                  ‘nursing care log system.’
                </p>
                <p>
                  By using this system, we can check and manage each resident’s
                  care log on computers and smartphones. You can check the vital
                  signs of the residents, such as temperature and blood
                  pressure, so it is very useful. An important item is
                  highlighted so you will not miss it. You can also leave
                  comments on the log so it makes it easier for us to
                  communicate with other coworkers and family members of the
                  residents.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                The log includes the residents’ likes and dislikes, or even
                pictures and movies taken at the nursing care facility. I think
                it is nice to share something that you can’t easily describe
                with words. I have seen and taken a video of a quiet resident
                doing the moonwalk (laughs).
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image5.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`Ucu`}>
                <p>
                  The families of the residents say that they are happy to know
                  how the residents spend their time while they are away.
                </p>
                <p>
                  I sometimes click ‘like’ on Ria’s post on the nursing care log
                  system.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`What are the distinctive features of your workplace? `}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img
                fluid={data.image6.childImageSharp.fluid}
                className={`mb20`}
              />
              <Img fluid={data.image7.childImageSharp.fluid} />
            </div>

            <div>
              <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
                <p>
                  There is a nursery school next to our workplace. My daughter
                  goes there.{" "}
                </p>
              </CommentText>
              <CommentText src={FaceImg01} name={`Ucu`}>
                <p>The children often visit us. </p>
                <p>
                  I used to take care of a resident with who I couldn’t
                  communicate well. But, one day, they mentioned my daughter and
                  asked me if she was well. The topic of my daughter became a
                  good opportunity to open their mind.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image9.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
                <p>
                  Besides the nursery school, there is also a place called{" "}
                  <ModalLink id={`modal-1`} style={{ display: `inline-block` }}>
                    <span>
                      ‘day service,’
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>Day Service</h3>
                      <p>
                        Elders or people with disabilities who live at their own
                        homes visit the nursing care service facility for a day.
                        They will be assisted with having meals and bathing.
                        Training programs for daily actions and some recreation
                        programs are also provided. They drive the users from
                        their homes to the facility.
                      </p>
                    </div>
                  </ModalLink>{" "}
                  both for elders and children, in the same building. In the
                  facility, there is a hair salon that opens twice a month and a
                  cooking room in which the residents can watch through the
                  window. There is even a food truck that comes and sells bread
                  and pastries. We also invite the neighbors once a month to
                  share a meal.
                </p>
                <p>
                  At the day service, the users enjoy a variety of recreation
                  such as{" "}
                  <ModalLink id={`modal-2`} style={{ display: `inline-block` }}>
                    <span>
                      Mahjong
                      <img src={IconInfo} alt="" />
                    </span>
                    <div>
                      <h3>Mahjong</h3>
                      <p>
                        It is a tile-based game that was developed in China and
                        usually played by four players. The players use their
                        fingers and memory so it is said to be effective for the
                        prevention of dementia.
                      </p>
                    </div>
                  </ModalLink>
                  , karaoke and exercise. When the residents visit the day
                  service, we need to help them to go to the toilet and get
                  dressed before they leave. We have a lot to do (laughs).
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image8.childImageSharp.fluid} />
            </div>
            <div>
              <CommentText src={FaceImg01} name={`Ucu`}>
                <p>
                  There are many things to do to care for each resident. So we
                  help each other and work together with staff with disabilities
                  and volunteer staff.
                </p>
              </CommentText>
            </div>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                When I’m free, I use my time in trying to get to know each
                resident more through making a care plan.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle
          question={`Do you have any difficulties to work as a Muslim?`}
        >
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image10.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                When I was looking for employment, I tried to find a workplace
                where hijabs are allowed. In fact, I haven’t felt any
                difficulties since I started working here.
              </p>
              <p>
                I also take care of male residents but they are old like my
                father. So I believe our God understands what I’m engaged in.
                Because this is my job.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                There is a mosque which is about 20 minutes drive away from our
                house. We go there every Friday. We live close to a mosque, and
                I can feel God close to me. Our relationship of trust becomes
                firm.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                I pray while working. When we are busy, I ask my coworkers if I
                can pray after things are settled down.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image11.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                We can basically pray at anytime but it is important to figure
                out the right timing. We usually pray five times a day, so when
                we can, we pray for about five minutes.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                During the period of Ramadan, when we fast from sunrise to
                sunset, I'm told that I don’t have to assist bathing. But I
                think I have the energy so I do it at my own responsibility. My
                coworkers tell me no to do it though, worrying that I may faint
                (laughs).
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>They care about us a lot.</p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`How is your life in Japan?`}>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image12.childImageSharp.fluid} />
            </div>

            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                Ucu and I work different shifts so the other one of us who works
                the late shift called{" "}
                <ModalLink id={`modal-3`} style={{ display: `inline-block` }}>
                  <span>
                    ‘Osoban’
                    <img src={IconInfo} alt="" />
                  </span>
                  <div>
                    <h3>Osoban</h3>
                    <p>
                      Because the residents live in the nursing care facility,
                      they are to be taken care of for 24 hours a day. The
                      working hours required by law are adjusted at the end of
                      every month. At Mori no Ie Narita, the morning shift
                      (Hayaban) starts at 7:15 am and ends at 4:15 pm, the late
                      shift (Osoban) starts at 11:00 am and ends at 8:00 pm, and
                      the overnight shift (Yakin) starts at 6:45 pm and ends at
                      8:45 am. Every worker takes an overnight shift five times
                      a month.
                    </p>
                  </div>
                </ModalLink>{" "}
                picks up and drops off our daughter. We also take turns
                preparing lunch. Ucu can cook so he helps me a lot.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                Recently, I see halal food more often than before at ‘Niku no
                Hanamasa,’ a popular supermarket in the Kanto area. We live
                close to the supermarket so it is very convenient.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                I don’t feel many difficulties living in Japan. We can take a
                vacation on days that we want twice a month so we have days off
                together.
              </p>
              <p>
                On our days off, we play in a park with our daughter or go to
                shopping malls like ‘AEON.’ Japan has distinctive four seasons
                unlike Indonesia, so we enjoy going out to tourist spots like
                Naritasan for seasonal leisure such as watching cherry blossoms
                and autumn leaves.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image13.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                There are a variety of horizontal relationships among
                Indonesians living in Japan.
              </p>
              <p>
                For example, there are EPA Indonesian communities such as ‘IPMI
                (Ikatan Perawat Muslim Indonesia)’ and ‘Keluarga Masyarakat
                Islam Indonesia (KMII).’ We join assemblies and plan trips
                together with friends. We traveled to Yamanashi and stayed
                overnight last year.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                One time, about 200 of the members gather from all over Japan
                for an IPMI event. We exchange information via LINE, Facebook
                and Instagram.
              </p>
              <p>
                If I were to say, I sometimes feel anxious about my daughter
                when she gets sick because we don’t have our parents and
                relatives living close.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>

          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                That’s true. But I think I'm lucky to be able to stay with my
                family and have a stable job. Ria and I work on the same floor
                so we can discuss if anything happens at the workplace.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image14.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                We know each other’s residents so we often talk about them at
                home. We both get really excited when there is happy news
                (laughs).
              </p>
              <p>
                It has been five years since we got married but our relationship
                hasn’t changed much. We argue sometimes but cooperate together
                to overcome difficulties to be a good family. We are not always
                super happy, but that’s what family is.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <QuestionAndAnswerToggle question={`What is your future goal? `}>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                My goal is to get permanent residency and have my own house in
                Japan. I also want to get a qualification for a certified nurse
                so I will study hard to get the JLPT N1.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                I want to go back to Indonesia someday because my parents are
                there. My goal is to get the JLPT N1 in two years time and
                become a translator. I own land in Indonesia so I want my house
                built there.
              </p>
              <p>
                From the second year, we can take a long vacation called
                ‘Refresh Vacation.’ You can leave for more than 12 days so I use
                the system every time I go back to Indonesia.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>I also want to go to Makkah with my family someday. </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div></div>
            <CommentText src={FaceImg02} direction={`right`} name={`Ria`}>
              <p>
                My three-year-old daughter pretends to pray but we still need to
                think about how we teach her Islam. The very first word she said
                was “Iyada,” which means “No” in Japanese (laughs). This is how
                much she is used to life in Japan. By the way, I'm pregnant with
                my second child.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
          <QuestionAndAnswer2colmun>
            <div>
              <Img fluid={data.image15.childImageSharp.fluid} />
            </div>
            <CommentText src={FaceImg01} name={`Ucu`}>
              <p>
                We want to build houses both in Japan and Indonesia because of
                our children. Even if we go back to Indonesia in the future, it
                will be easier for them to come back and study at university as
                long as there is a place to live. We want to give our children a
                lot of options and an adequate living environment wherever they
                go.
              </p>
            </CommentText>
          </QuestionAndAnswer2colmun>
        </QuestionAndAnswerToggle>

        <div className={`mt30`}>
          <Info2column noBorder={true}>
            <div></div>
            <div>
              <p style={{ margin: 0 }}>Workplace</p>
              <h3 className={`mb10`}>
                <ModalLink id={`facility`} className={`linkUnderline`}>
                  <span style={{ color: "black" }}>
                    Mori no Ie Narita
                    <img src={IconPhoto} alt="" />
                  </span>
                  <div>
                    <h3>Workplace</h3>
                    <Img fluid={data.facility1.childImageSharp.fluid} />
                    <Img fluid={data.facility2.childImageSharp.fluid} />
                    <Img fluid={data.facility3.childImageSharp.fluid} />
                    <Img fluid={data.facility4.childImageSharp.fluid} />
                    <Img fluid={data.facility5.childImageSharp.fluid} />
                  </div>
                </ModalLink>
              </h3>
              <p className={`mb40`}>
                They operate an intensive care home for the elderly (maximum
                capacity of 100 people), a short stay service facility (maximum
                capacity of 10 people) and an adult daycare center. There is
                also a nursery school and child daycare center. There is even
                some staff with disabilities so a variety of people live in the
                same environment.
              </p>
              <p>
                <a
                  href={`https://www.gakudan.org`}
                  rel={`noopener noreferrer`}
                  target={`_blank`}
                >
                  https://www.gakudan.org
                </a>
              </p>
              <div className="credit">
                <p>
                  Text by Tami Ono
                  <br />
                  Photo by Isamu Sakamoto
                  <br />
                  Translation by Mihoko Tsujita, Dean Humphrey
                </p>
              </div>
              <div className="mt40">
                <ColumnGrid>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/2`} align={`center`}>
                      PREV
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/`} align={`center`}>
                      BACK
                    </CommonButton>
                  </div>
                  <div className={css.commonButton}>
                    <CommonButton to={`/en/interview/1`} align={`center`}>
                      NEXT
                    </CommonButton>
                  </div>
                </ColumnGrid>
              </div>
            </div>
          </Info2column>
        </div>
      </LayoutInterview>
    </div>
  )
}

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "interview/03/01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image2: file(relativePath: { eq: "interview/03/02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image3: file(relativePath: { eq: "interview/03/03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image4: file(relativePath: { eq: "interview/03/04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image5: file(relativePath: { eq: "interview/03/05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image6: file(relativePath: { eq: "interview/03/06.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image7: file(relativePath: { eq: "interview/03/07.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image8: file(relativePath: { eq: "interview/03/08.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image9: file(relativePath: { eq: "interview/03/09.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image10: file(relativePath: { eq: "interview/03/10.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image11: file(relativePath: { eq: "interview/03/11.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image12: file(relativePath: { eq: "interview/03/12.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image13: file(relativePath: { eq: "interview/03/13.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image14: file(relativePath: { eq: "interview/03/14.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    image15: file(relativePath: { eq: "interview/03/15.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    facility1: file(relativePath: { eq: "interview/03/facility_01.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility2: file(relativePath: { eq: "interview/03/facility_02.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility3: file(relativePath: { eq: "interview/03/facility_03.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility4: file(relativePath: { eq: "interview/03/facility_04.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    facility5: file(relativePath: { eq: "interview/03/facility_05.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 600, quality: 98) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`
